/* General Layout */
@import url('https://fonts.googleapis.com/css2?family=Playwrite+US+Trad:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  letter-spacing: 0.2px;
}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #BEBEBEF2; /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
}
/* For Firefox */
/* * {
  scrollbar-width:thin; 
  scrollbar-color: #888 #f1f1f1; 
} */

h2{
  margin: 0px;
}
h3{
  margin: 0px;
}
p{
  margin: 0px;
}
input{
  outline: none;
}
textarea{
  outline: none;
}
:root {
  --primary-color: #40513B;
  --primary-color-hover: #3e7b61 ;
  --secondary-color: #609966;
  --background-color: #F2F2F2;
  --font-color: #060606;
  --Grays-White: #fff;
  --font-color-sidebar: #9DC08B;
  --read-input-box-bg-color:#f2f2f2;
  --sidebar-icon-filter:'none'
}
.app{
  display: flex;
  /* height: 100vh; */
}

.main-contant{
  width: 100%;
  background-color: var(--background-color);
}
.main-bachground{
  background-color: var(--background-color);
  /* border-radius:20px 0px 0px 20px; */
  margin-bottom: 30px;
  padding: 0px 2px 20px 2px;
}
.dashboard-container{
  background-color: var(--primary-color);
  /* background-color: var(--background-color); */
  width: 100%;
  min-height: 100vh; 
  overflow: hidden;
}

.button {
  background-color: var(--primary-color);
  color: var(--font-color);
  padding: 10px 20px;;
  border: none;
  cursor: pointer;
}

.text {
  color: var(--font-color);
}

.background {
  background-color: var(--background-color);
}
@media (max-width: 950px) {
  .wrap-div{
    flex-wrap: wrap;
  }
}

.nth-child-color:nth-child(even){
  background-color: #F7F8FA;
}
.add-popup-buttons{
  background: #fff;
  border-radius: 20px;
  padding: 7px 20px;
  border: 1px #858585 solid;
  cursor: pointer;
  color: #40513B;
  font-size: 12px;
  padding-left: 13px;
}
/* General Layout */
.dashboard-screen {
  display: flex;
  flex-direction: row;
}

.main-content {
  flex: 1;
  width: 100%;
  /* height: 100vh;  */
}
.flexdisplay{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.flexdisplaybetween{
  display: flex;
  justify-content:space-between;
  align-items: center;
  gap: 10px;
}
.list-error-msg{
  color: red;
  text-align: center;
  font-size: 12px;
  padding: 10px;
}

/* src/Loader.css */
.loader-fixed {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.loader-container{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background: '#00000080'; */
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  /* border-top-color: #007bff; */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.smallSpinner {
  width: 12px;
  height: 12px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  /* border-top-color: #007bff; */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-skeleton {
  background: linear-gradient(
    90deg,
    #e0e0e0 25%,
    #f8f8f8 50%,
    #e0e0e0 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* overlay loader for div parent div position:relative */
.overlay-div{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #3738380a;
}
.loader-overlay {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid var(--primary-color);
  width: 36px;
  height: 36px;
  animation: spining 1s linear infinite;
}

@keyframes spining {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
/* Sidebar Styling */

.menu-icon-div-sty{
  display: flex;
  justify-content: center;
  align-items:center;
  /* margin-left: auto; */
  cursor: pointer;
  margin-bottom: 5px;
}
.menu-icon-sty{
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}
.down-icon-sty{
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1); /* Turns the image white */
}


.rotate-icon {
  transform: rotate(180deg); /* Adjust the degrees of rotation as needed */
}

@media (min-width: 81px) and (max-width: 958px) {
  .app-contant {
    width: calc(100% - 50px); /* Reduces the width by 50px */
    background-color: var(--background-color);
  }
  .displayNone{
    display: none;
  }
  .sidebar-menu button {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 14px;
    cursor: pointer;
    justify-self: center;
    width: 100%;
  }

}
@media (min-width: 958px) and (max-width: 3708px) {
  .clientInfo-sty{
    max-width: 30%;
    /* margin-right: 25;
    margin-top: -15; */

  }
  .app-contant{
    width: calc(100% - 18%);
    background-color: var(--background-color);
  }
  .displayNone{
    display: block;
  }
  .sidebar-menu button {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
  }
}
.smallLogo-Sty {
  width: 20px;
  /* position: relative; */
  height: 20px;
}
.companyname-Sty {
  color: var(--Grays-White, #FFF);
  font-family: "Playwrite US Trad";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 164.286% */
  letter-spacing: 0.5px;
  text-align: center;
}
.companyInfo-sty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  /* padding: 15px; */
  flex: auto;
}
.sidebar {
  width: 15%;
  max-width: 100%; /* Ensure sidebar doesn’t exceed screen width */
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
  flex: 0 0 auto; /* Make it flexible in smaller screens */
}

.sidebar-profile {
  text-align: center;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.profile-image-min {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.user-name-sidebar{
  font-family: 'Roboto Slab';
  font-weight: 400;
  font-size: 16px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  width: 100%;
  /* margin-top: 20px; */
}
.sidebar-menu-active{
  position: absolute;
  width: 250px;
  background-color: var(--primary-color);
  z-index: 999999;
}
.display-full-height{
  /* height: 100vh; */
  width: 80%;
}



.sidebar-menu-li {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--font-color-sidebar);
  text-align: left;
}
.sidebar-menu-li span{
  padding-left: 15px;
}
.active {
  /* background-color: white;  */
  color: white; /* Change text/icon color */
  /* border-radius: 5px;  */
}

.sidebar-menu button:hover {
  background-color: var(--primary-color-hover);
}

/* .sidebar-menu-li.active, */
.dropdown-link.sub-active {
  /* background-color: #1f384c; */
  color: white;
}

.dropdown-link.sub-inactive {
  /* color: #627b87; */
}
.sidebar-icon { 
  /* margin-right: 10px; */
  width: 20px;
  height: 20px;
}
.sidebar-icon-white{
  filter: brightness(0) invert(1); /* Turns the image white */
}
.icon-color-sidebar{
  filter: var(--sidebar-icon-filter);
}
.logout-icon{
  /* margin-right: 10px; */
  width: 16px;
  height: 16px;
}
.center-alin-sty{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background: none;
  border: none;
  color: white;
  width: 100%;
}

.sidebar-logout {
  margin-top: auto;
  margin-bottom: auto;
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  /* background-color: #3e7b61; */
}

.sidebar-logout:hover {
  background-color: var(--primary-color-hover);
}

/* Header Styling */
.header-div{
  background-color: var(--primary-color);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--background-color);
  padding: 20px 0px 20px 20px;  
  border-top-left-radius: 30px;
}

.header-greeting h1 {
  font-size: 24px;
  margin: 0;
}

.header-greeting p {
  font-size: 14px;
  color: #6b7280;
}
.search-input::placeholder {
  color: #999;
  /* opacity: 0.5;  */
}

.search-input::-ms-input-placeholder { /* Edge 12-18 */
  color: #999;
  /* opacity: 0.5;  */
}
.grayscale {
  filter: brightness(2) invert(1); /* Turns the image gray */
}

.header-controls {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 20px;
  flex: auto;
  justify-content: end;
}

.search-bar {
  position: relative;
  /* margin-right: 20px; */
}

.search-bar input {
  padding: 8px 12px 8px 30px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #d1d5db;
  outline: none;
  width: 200px;
}

.search-icon {
  /* position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #9ca3af; */
}

.add-client-button-header {
  display: flex;
  align-items: center;
  background-color: white;
  color: var(--primary-color);
  border: 1px solid #858585;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
}





.notification-button {
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 8px;
  cursor: pointer;
  /* margin-right: 20px; */
  border-radius: 5px; 
  border: 1px solid #E0D9D9
}
.notification-button:hover{
  background-color: #d1d5db;
}

/* Header title Styling */
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-size: 24px;
  font-weight: 500;
  color: #060606;  
  letter-spacing: 1px;
}



.dropdown {
  /* margin-bottom: 10px; */
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3b4a42;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.dropdown-title {
  flex: 1;
  text-align: left;
}

.dropdown-arrow {
  font-size: 12px;
  color: #ccc;
}

.dropdown-content {
  margin-top: 5px;
  /* padding: 10px; */
  border-radius: 5px;
  padding-left: 5px;
}

.dropdown-link {
  display: block;
  padding: 10px 10px 10px 30px;
  /* color: var(--font-color-sidebar); */
  text-decoration: none;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px dotted #609966;
  background-color: #2C3829;
  border-radius: 5px;
}
.sub-active{
  color: #fff;
}
.sub-inactive{
  color: var(--font-color-sidebar);
}

.dropdown-link:last-child {
  /* border-bottom: none; */
}
.dropdown-link:nth-child(even){
  border-bottom: 1px dotted #60996687;
}

.dropdown-link:hover {
  background-color: #475c44;
  color: white;
}
.dropdown-link-sub{
  display: block;
  padding: 10px 10px 10px 30px;
  /* color: var(--font-color-sidebar); */
  text-decoration: none;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px dotted #609966;
  background-color: #151B13;
  border-radius: 5px;
}
.dropdown-link-sub:hover{
  background-color: #475c44;
  color: white;
}



/* Dashboard Good Morning Section Start */
.good-morning-section {
  padding: 20px;
  background-color: var(--background-color);
  display: flex;
  gap: 10px;
  /* align-items: flex-start; */
}
.task-info-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  gap: 5px;
}

.task-container-wrapper {
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling */
  max-width: 100%; /* Ensure the wrapper doesn't exceed the parent width */
  padding-bottom: 10px;
  flex: 1;
  scroll-behavior: smooth; /* Enable smooth scrolling */
}
.task-container-wrapper::-webkit-scrollbar {
  display: none;
}

.task-container {
  display: inline-flex;
  gap: 15px;
}

.add-task-card {
  width: 70px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dotted #000;
  cursor: pointer;
  border-radius: 15px;
  margin-right: 15px;
  flex-shrink: 0;
  padding: 10px;
}

.plus-sign {
  font-size: 30px;
  color: #666;
}

.task-card {
  flex: 0 0 auto;
  width: 100px;
  background-color: #3c6e47;
  color: white;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  position: relative;
}

/* .task-type {
  color: white;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 400;
  margin-top: 0px;
  font-family: 'Roboto Slab';
  line-height: 1.1;
} */
.task-type {
  color: white;
  font-size: 12px;
  font-weight: 400;
  height: 56px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-title {
  color: #f3ecec;
  font-size: 11px;
  font-family: 'Roboto Slab';
  font-weight: 300;
  height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-time-div{
  height: 29px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 10px;
}

.task-date {
  color: 'white';
  font-size: 9px;
  /* margin-bottom: 2px; */
  font-family: 'Roboto Slab';
  font-weight: 300;
}

.task-time {
  font-size: 9px;
  margin-top: 2px;
  color: 'white';
  /* font-family: 'Montserrat'; */
  font-weight: 500;
}

.task-details-button {
  border: none;
  color: black;
  cursor: pointer;
  font-size: 15px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  align-self: flex-end;
  font-weight: bold;
  justify-content: center;
  display: flex;
  align-items: center;
}
/* Dashboard Good Morning Section End */

/* Dashboadrd Notification and Task Overview Start */
.notifications-taskoverview-wrapper {
  width: 96%; /* Match the width of your tables */
  margin: 20px 0px 0px 20px;
}

.notifications-section {
  background-color: #EAFFD0;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border: 2px solid #FCE38A;
  border-bottom: 1px solid #d1e7a3;
  border-radius: 10px 10px 3px 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.notifications-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.notifications-header h3 {
  font-weight: bold;
  margin-right: 15px;
}

.notification-message {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  color: '#060606';
  font-size: 14px;
  /* font-family: 'Montserrat'; */
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-navigation {
  display: flex;
  gap: 5px;
  flex-shrink: 0;
}

.nav-button {
  border: 0px solid rgb(219, 219, 219);
  /* background-color: white; */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #333;
}

.rotate-icon {
  transform: rotate(180deg); /* Rotate to left */
}

.task-overview-section {
  background-color: #ffffff;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.overview-content {
  display: flex;
  justify-content: space-between; /* Distributes items evenly */
  align-items: center; /* Vertically centers the content */
  /* width: 100%; Takes up the full width */
  gap: 20px; /* Adds space between items */
  flex-wrap: wrap;
}

.task-overview-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  /* margin-right: 30px; */
  color:#060606;
  font-family: "Roboto Slab";
  flex: auto;

}

.task-overview-item {
  /* text-align: left; */
  flex:auto
  /* margin-left: 30px;  */
}

.task-count {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  color: #060606;
  font-family: 'Roboto Slab';
  font-weight: 400;
}

.task-label-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px; /* Space between the label and the trend icon */
}

.task-label {
  font-size: 14px;
  color: #818186;
  margin: 0;
  font-family: 'Roboto';
  font-weight: 400;
}

.trend-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Makes the icon a circle */
  font-size: 12px;
  color: #ffffff; /* White arrow color */
}

.trend-icon.up {
  /* background-color: #28a745;  */
}

.trend-icon.down {
  /* background-color: #dc3545;  */
}

.add-button {
  background-color: #ffffff;
  border: 2px dotted #606060;
  border-radius: 14%;
  color: #7c7c7c;
  font-size: 49px;
  width: 65px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0 0 auto;
}

.notifications-heading{
  color: '#060606';
font-size: 18;
font-family: 'Roboto Slab';
font-weight: '500';
}

/* Dashboadrd Notification and Task Overview End */

/* Dashboadrd Tables Start */
.tables-container {
  display: flex;
  gap: 20px;
  width: 96%; /* Match the width of your tables */
  margin: 20px 0px 0px 20px;
  flex-wrap: wrap;
}

.table-section {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.table-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #060606;
  font-family: 'Roboto Slab';
}

.table-header .table-cell.client-info .header-text {
  margin-left: 25px; /* Moves only the Client text, not the entire cell */
}

.table-header-card {
  background-color: #F7F8FA;
  border-radius: 10px;
  /* padding: 10px 5px; */
  margin-bottom: 15px;
}

.table-header,
.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-cell {
  padding: 10px 10px;
  font-size: 12px;
}

.table-cell.client-info {
  flex: 1.2; /* Make client column slightly wider */
  font-size: 12px;
font-weight: 500;
font-family: 'Roboto';
color: #505470;
}

.table-cell.tasks {
  flex: 1.2; /* Make tasks column slightly wider */
  font-size: 12px;
font-weight: 500;
font-family: 'Roboto';
color: #505470;
  /* margin-top: 5px; */
}

.table-cell.due-date {
  flex: 1; /* Standard width for due date */
  font-size: 12px;
font-weight: 500;
font-family: 'Roboto';
color: #505470;
  /* margin-top: 5px; */
  height: 14px;
}

.message-icon {
  width: 16px; /* Adjust the size as needed */
  height: 16px;
  margin-right: 8px; /* Space between the icon and the client info */
  vertical-align: middle; /* Aligns icon with the text */
}

.actions-header,
.actions {
  flex: 0.6; /* Narrower actions column */
}

.client-info {
  display: flex;
  align-items: center;
  gap: 5px; /* Adjust gap if needed */
}

.client-name {
  font-size: 12px;
font-weight: 500;
font-family: 'Roboto';
color: #192a3e;
  margin: 0;
  padding: 0;
  /* height: 16.9px; */
}

.client-phone {
  font-size: 10px;
font-weight: 500;
font-family: 'Poppins';
color: #90a0b7;
  margin: 0;
  padding: 0;
  padding-top: 8px;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 3px;
}

.actions-header {
  width: 30px;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.action-button.edit-icon::before {
  content: '✏️';
}

.action-button.delete-icon::before {
  content: '🗑️';
}

.action-button:hover {
  color: #007bff;
}

/* Pagination Styles */
.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.total-count {
  font-size: 12px;
  color: #232638;
  font-weight: 500;
  font-family: 'Roboto';
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-button {
  background-color: #ffffff;
  border: 1px solid #ddd;
  color: #000;
  padding: 5px 10px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 5px;
}

.pagination-button.active {
  background-color: #000;
  color: #fff;
  border-color: #000;
}

.pagination-button:hover {
  background-color: #333;
  color: #fff;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.pagination-info {
  font-size: 12px;
  color: #232638;
  margin-right: 15px;
  font-family: 'Roboto';
  font-weight: 500;
}
.pagination-button-arrow {
  background-color: #ffffff;
  border: 1px solid #ddd;
  color: #000;
  padding: 3px 5px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 5px;
}
.table-cell.description {
  flex: 1.5; /* Increase width for the Description column */
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: Shows ellipsis if the content overflows */
  word-break: break-word; /* Allows text to break into the next line if too long */
  font-size: 12px;
  color: #505470;
  font-weight: 500;
  font-family: 'Roboto';
  /* font-weight: bold; */
}

.table-cell.due-dates {
  flex: 0.7; /* Reduce width for the Due Date column */
  font-size: 12px;
font-weight: 500;
font-family: 'Roboto';
color: #505470;
/* padding-bottom: 5px; */
  /* font-weight: bold; */
}

.todo-description{
  font-size: 13px;
  color: #192a3e;;
  font-weight: 400;
  font-family: 'Roboto';
  /* padding-bottom: 5px; */
  /* padding-left: 20px; */
}

.divider {
  border: none;
  border-top: 0.5px solid #e4e4e4; /* Light gray line */
  margin: 10px 0; /* Space around the line */
}
.description-radio {
  display: flex;
  align-items: center;
}

.description-radio input[type="radio"] {
  transform: scale(1.5); /* Increase size (1.5x) */
  /* margin: 10px; */
}
.padding-left-des{
  padding-left: 30px;
}
.icon-image {
  width: 16px; /* Adjust size as needed */
  width: 16px;
}

.arrow-left {
  transform: rotate(90deg); /* Rotate to left */
}

.arrow-right {
  transform: rotate(-90deg); /* Rotate to right */
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggle-container {
  display: flex;
  gap: 2px;
}

.toggle-option {
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  color: #777;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-size: 13px;
}

.toggle-option.selected {
  color: #060606;
  font-size: 10px;
  font-weight: 400;
  font-family: 'Roboto';
}
.toggle-option.not-selected {
  color: #BABABA;
  font-size: 10px;
  font-weight: 400;
  font-family: 'Roboto';
}
/* Dashnoard tables End */

/* Dashboard Quick Action Code start*/
.quick-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #edf4e0; /* Light green background */
  border-radius: 30px;
  width: 96%; /* Match the width of your tables */
  margin: 20px 0px 20px 20px;
  padding: 25px 0px;
  flex-wrap: wrap;
}

.quick-actions-title {
  color: #060606;
font-size: 18px;
font-family: 'Roboto Slab';
font-weight: bold;
height: 50px;
height: 80px;
/* letter-spacing: 0.18; */
}

.quick-actions-cards {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.quick-action-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns content to the left */
  background-color: #ffffff;
  border-radius: 20px;
  padding: 15px;
  min-width: 110px; /* Ensures the card doesn't shrink below this width */
  min-height: 80px; /* Ensures the card doesn't shrink below this height */
  width: auto; /* Allows the card to grow based on content */
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
  transition: background-color 0.3s, box-shadow 0.3s;
}

.quick-action-card.active {
  background-color: #3a5336; /* Dark green for active card */
  color: #fff;
}

.quick-action-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Darker shadow on hover */
}

.quick-action-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns label and icon to the left */
}

.quick-action-label {
  font-size: 14px;
  margin: 0 0 5px 0; /* Margin below label */
  color: inherit;
  /* text-align: left; */
}

.quick-action-icon {
  width: 30px;
  height: 30px;
  background-color: #3a5336; /* Dark green background */
  color: #fff;
  border-radius: 30%; /* Rounded icon */
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow around the icon */
  margin-top: 20px;
}

.quick-action-card.active .quick-action-icon {
  background-color: #3a5336; /* White icon background for active card */
  color: #000000; /* Dark green icon color for active card */
}

.quick-action-icon span {
  display: inline-block;
  transform: translateY(-1px); /* Slight vertical alignment for the "+" symbol */
}
/* Dashboard Quick Action Code End */

/* DashBoard Upcoming Appointments Start */
.dashboard-container-Upcoming-Appointments {
  display: flex;
  background-color: var(--background-color);
  margin-bottom: 15px;
  border-radius: 20px 0px 0px 20px;
  flex-wrap: wrap;
}

/* Left section with black background and vertical border */
.left-section {
  /* background-color: #000; */
  flex: 1;
  border-right: 1px solid #535353; /* Light gray border to separate sections */
}

/* Right section styling */
.right-section {
  flex: 1;
  padding: 20px;
  padding-right: 40px;
  padding-top: 0px;
}

/* Header styling */
.appointments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
}

.appointments-header h3 {
  font-size: 24px;
  margin: 0;
}

.filter-options {
  display: flex;
  gap: 15px;
  color: #6b7280;
}

.filter-options span {
  cursor: pointer;
}

/* Appointments list styling */
.appointments-list {
  display: flex;
  flex-direction: column;
}

/* Appointment item styling with bottom border */
.appointment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e5e7eb; /* Light gray horizontal line */
  cursor: pointer;
}

.appointment-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.appointment-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}


.appointment-name {
  color: #273240;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
  margin: 0;
}

.appointment-company {
  color: #273240;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto';
  margin: 0;
}

/* Status badge styling */
.appointment-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

/* Radio button styling */
input[type="radio"] {
  accent-color: #4b5563;
  cursor: pointer;
}
.filter-options span {
  cursor: pointer;
  color: #6b7280; /* Default color */
  font-size: 12px;
}

/* Selected option style */
.filter-options .selected {
  color: #000; /* Black color for selected option */
  font-weight: bold;
}

.activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-left: 45px;
  /* gap: 15px; Space between the title and dropdown */
}

.activity-title {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  color:#060606;
  font-family: 'Roboto Slab';

}

.dropdown-select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #a8a8a8;
  cursor: pointer;
  border-radius: 20px;
}
.dropdown-select {
  appearance: none; /* Hide the default dropdown arrow */
  background-image: url('./Assets//Icons/chevron-down.png');
  background-repeat: no-repeat;
  background-position: right 10px center; /* Position the icon */
  background-size: 20px 22px; /* Adjust icon size */
  padding-right: 30px; /* Space for the custom arrow */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
}

.Upcoming-Appointments-header{
  color: #060606;
  font-size: 18px;
  font-family: 'Roboto Slab';
  font-weight: 400;
}
.filter-options {
  display: flex;
  gap: 15px;
  color: #6b7280;
}

.filter-options span {
  cursor: pointer;
}

.filter-options .selected{
  color: #060606;
  font-size: 10px;
  font-family: 'Roboto';
  font-weight: 400;
}
.filter-options .not-selected{
  color: #BABABA;
  font-size: 10px;
  font-family: 'Roboto';
  font-weight: 400;
}

/* Dashboard Upcoming Appointments End */

/* Login Page Style Start */
.backIcon {
  /* position: absolute;
  top: -81.48px;
  left: -224.41px; */
  /* width: 1770px;
  height: 1156.1px; */
  object-fit: cover;
  opacity: 0.7;
}

.artboard113WfAutomationIllWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.logimgstyle{
  width: 100%;
  min-width: 250px;
}
.frameChild {
  width: 42px;
  /* position: relative; */
  height: 41.7px;
}
.companyName {
  /* position: relative; */
  letter-spacing: 0.02em;
}
.groupParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 24px;
  color: #1a1e29;
  font-family: 'Playwrite US Trad';
}
.xodimlar {
  align-self: stretch;
  /* position: relative; */
  letter-spacing: 0.5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 12px;
  flex-shrink: 0;
}
.text {
  flex: 1;
  /* position: relative; */
  letter-spacing: 0.25px;
  line-height: 120%;
  font-weight: 300;
}
.textWrapper {
  width: 328px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d6e4ec;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  font-size: 16px;
  color: #aabbc6;
}
.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.label {
  /* position: relative; */
  line-height: 28px;
}
.button {
  width: 100%;
  max-width: 290px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: #40513b;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 30px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  color: #fbfbfb;
  line-height: 28px;
}
.frameGroup {
  align-self: stretch;
  /* width: 500px; */
  border-left: 1px solid rgba(26, 30, 41, 0.12);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;
  gap: 25px;
  flex: 1;
  overflow: auto;
}

.forgotPassword {
  text-decoration: underline;
  font-weight: 500;
  color: #8388a2;
  cursor: pointer;
  /* align-self: flex-start; */
  width: 100%;
  max-width: 290px;
  /* flex: 1; */
  /* padding-left: 60px; */
  margin-top: 0px;
}
.login {
  background-image: url(./Assets/Images/bgImg.png);
  width: 100%;
  background-color: #edf1d6;
  height: 100vh;
  overflow: auto;
  text-align: left;
  font-size: 12px;
  color: #343c44;
  font-family: Roboto;
  background-repeat: round;
  object-fit: fill;
  display: grid;
  place-items: center;
}

.input-field {

  /* height: 46px;
  border: 1px solid #d6e4ec;
  border-radius: 6px;
  padding: 0 20px;
  background-color: #fff;
  width: 100%;
  min-width: 270px;
  box-sizing: border-box;
  padding-right: 40px; 
  outline: none; */
  height: 40px;
  margin-top: 5px;
    border: 1px solid #d6e4ec;
    border-radius: 6px;
    padding: 0 20px;
    background-color: #fff;
    min-width: 221px;
    box-sizing: border-box;
    outline: none;
    /* border: none; */
}

.input-field::placeholder {
  color: #999;
}
/* .input-container2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border: 1px solid #d6e4ec;
  border-radius: 6px;
  padding: 10px 20px;
  background-color: #fff;
  min-width: 221px;
} */

.right-element {
  position: absolute;
  right: 20px;
  top: 60%;
  transform: translateY(-50%);
}

.toggle-button {
  background: none;
  border: none;
  /* color: #007bff; */
  cursor: pointer;
}

@media (min-width: 81px) and (max-width: 908px) {
  .frameParent {

    border-radius: 16px;
    background-color: #fff;
    max-width: 550px;
    min-height: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 72px 0px;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
}

@media (min-width: 909px) and (max-width: 2908px){
  .frameParent {

    border-radius: 16px;
    background-color: #fff;
    max-width: 1000px;
    min-height: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 72px 0px;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
}

/* General Layout */
.tasksScreen-task-screen {
  display: flex;
  background-color: var(--background-color);
  min-height: 100vh;
}

.tasksScreen-main-content {
  margin-left: 20px;
  padding: 0px 20px 20px 20px;
  /* width: 100%; */
  background-color: var(--background-color);
}

/* Container for Task Details and Table */
.tasksScreen-tasks-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.tasksScreen-tasks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
  font-family: 'Roboto Slab';
  font-size: 28px;
  color: #1F384C;
  font-weight: 500;
}

.tasksScreen-tasks-title {
  font-size: 28px;
  font-weight: bold;
  color: #1F384C;
}

.tasksScreen-add-task-button {
  background-color: #007bff;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.tasksScreen-add-task-button:hover {
  background-color: #0056b3;
}

/* Filters Button */
.tasksScreen-filters-row {
  background-color: #F7F8FA;
  padding: 12px 0;
  border: 1px solid #EFF0F4;
}

.tasksScreen-filters-button {
  background-color: #ffffff;
  color: #40513B;
  border: 1px solid #d1d1d1;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tasksScreen-filters-button:hover {
  background-color: #f1f3f5;
}

.tasksScreen-filters-button-wrapper {
  padding-left: 12px;
}

/* Filter Section */
.tasksScreen-filters-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.tasksScreen-filters-container.tasksScreen-open-filters {
  border: 1px solid #EFF0F4;
}

.taskScreen-filters-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.tasksScreen-filters {
  /* display: flex;
  grid-template-columns: repeat(4, 1fr); */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}
.tasksScreen-filters-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 50px;
  width: 100%;
}

.tasksScreen-filters label {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #555;
}

.tasksScreen-filters select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  font-size: 12px;
  color: #333;
}

.tasksScreen-filters select:focus {
  outline: none;
  border-color: #6c757d;
}

/* Filter Buttons */
.tasksScreen-filter-buttons {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}

.tasksScreen-apply-button,
.tasksScreen-cancel-button,
.tasksScreen-reset-button {
  padding: 8px 30px;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
}

.tasksScreen-apply-button {
  background-color: #40513B;
  color: #FBFBFB;
  border: none;
}

.tasksScreen-apply-button:hover {
  background-color: #3f5f5f;
}

.tasksScreen-cancel-button {
  border: 1px solid #40513B;
  background: none;
  color: #40513B;
}

.tasksScreen-cancel-button:hover {
  background-color: #e0e0e0;
}

.tasksScreen-reset-button {
  background: none;
  border: none;
  color: #40513B;
}

.tasksScreen-reset-button:hover {
  color: #333;
  text-decoration: none;
}

/* Task Table */
.tasksScreen-task-table-wrapper {
  overflow-x: auto;
}

.tasksScreen-task-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #F7F8FA;
  border: 1px solid #EFF0F4;
}


.tasksScreen-task-table th,
.tasksScreen-task-table td {
  padding: 10px 12px;
  font-size: 12px;
  font-family: 'Roboto';
}


.tasksScreen-task-table th {
  font-weight: 600;
  text-align: left;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  color: #505470;
  font-family: sans-serif;
}


.tasksScreen-task-table td {
  border-bottom: 1px solid #f1f1f1;
  word-wrap: break-word; 
  white-space: normal; 
  overflow: hidden; 
  max-width: 100px;
  font-weight: 400;
  color: #192A3E;
}


.tasksScreen-task-table td button {
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: #555;
  padding-right: 5px;
}

.tasksScreen-task-table td button:hover {
  color: #333;
}

/* Main Task Row Styling */
.tasksScreen-main-task-row {
  transition: border-bottom 0.3s ease;
}

/* Additional Information Row Styling */
.tasksScreen-additional-info-row {
  font-size: 12px;
  color: #555;
  background-color: #ffffff;
  padding: 10px 0;
  border-bottom: 2px solid #060606;
}

.tasksScreen-additional-info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.tasksScreen-additional-info div {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.tasksScreen-additional-info div p {
  margin: 0;
  color: #060606;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  word-wrap: break-word; 
  white-space: normal; 
  overflow: hidden; 
}

.tasksScreen-additional-info div span {
  font-weight: 400;
  color: #767676;
  margin-bottom: 5px;
  font-family: 'Roboto';
  font-size: 12px;
}

/* Status Badge Styling */
.tasksScreen-status-badge {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #333;
  text-align: center;
}

.tasksScreen-status-badge.in-progress {
  background-color: #1A932E2E;
  color: #1A932E;
}

.tasksScreen-status-badge.completed {
  background-color: #1A932E2E;
  color: #1A932E;
}

.tasksScreen-status-badge.not-started {
  background-color: #E2B1332E;
  color: #DFA510;
}

.tasksScreen-status-badge.new {
  background-color: #E2B1332E;
  color: #DFA510;
}

.tasksScreen-status-badge.reopen {
  background-color: #E2B1332E;
  color: #DFA510;
}

.tasksScreen-status-badge.priority {
  background-color: #EE201C2E;
  color: #EE201C;
}

.tasksScreen-status-badge.hold {
  background-color: #EE201C2E;
  color: #EE201C;
}
.tasksScreen-status-badge.issue {
  background-color: #EE201C2E;
  color: #EE201C;
}

.tasksScreen-status-badge.delayed {
  background-color: #E65F2B2E;
  color: #E65F2B;
}

/* Action Buttons */
.tasksScreen-edit-button,
.tasksScreen-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0 4px;
  color: #6c757d;
}

.tasksScreen-edit-button:hover {
  color: #007bff;
}

.tasksScreen-delete-button:hover {
  color: #dc3545;
}

/* Pagination Styling */
.tasksScreen-table-footer {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
  color: #333;
}

.tasksScreen-pagination-button {
  background-color: #ffffff;
  border: 1px solid #ddd;
  color: #505470;
  padding: 5px 10px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tasksScreen-pagination-button.active {
  background-color: #609966;
  color: #fff;
}

.tasksScreen-pagination-button:hover {
  background-color: #e6e1e1;
  color: #609966;
}

.tasksScreen-pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.tasksScreen-pagination-info {
  color: #333;
  font-size: 14px;
  margin-right: 10px;
}

.tasksScreen-pagination {
  display: flex;
  align-items: center;
}

.tasksScreen-icon-image {
  width: 10px; 
  height: auto;
}

.tasksScreen-arrow-left {
  transform: rotate(90deg);
}

.tasksScreen-arrow-right {
  transform: rotate(-90deg); 
}

.tasksScreen-arrow-up {
  transform: rotate(180deg);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tasksScreen-filters {
    grid-template-columns: repeat(2, 1fr);
  }

  .tasksScreen-task-table th,
  .tasksScreen-task-table td {
    padding: 8px;
  }

  .tasksScreen-tasks-title {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .tasksScreen-main-content {
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }

  .tasksScreen-filters {
    grid-template-columns: 1fr;
  }

  .tasksScreen-filter-buttons {
    flex-direction: column;
  }

  .tasksScreen-apply-button,
  .tasksScreen-cancel-button {
    padding: 10px;
    width: 100%;
  }

  .tasksScreen-task-table-wrapper {
    overflow-x: auto;
  }

  .tasksScreen-tasks-title {
    font-size: 18px;
  }

}
/* Login Page Style End */

/* AddClient Modal start */
/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;  
  max-height: 100%;
  overflow: auto;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 600px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}
.modal-content-m {
  animation: fadeRight 0.25s ease forwards; /* Trigger animation */
}
/* Keyframes for fade-right animation */
@keyframes fadeRight {
  0% {
    transform: translate(20%, 0); /* Starts slightly outside the window */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0); /* Ends inside the window */
    opacity: 1;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  color: #40513B;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.6px;
  /* font-family: 'Montserrat'; */
}

.title-divider,
.footer-divider {
  border: none;
  border-top: 1px solid #d1e3ed;
  margin: 10px 0;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* display: flex;
  flex-wrap: wrap; */
  gap: 14px;
  column-gap: 20px;
  row-gap: 14px;
}

.existing-client-checkbox {
  display: flex;
  align-items: center;
  grid-column: span 2;
  margin-top: 10px;
}

.existing-client-checkbox label {
  font-size: 14px;
  margin-right: 5px;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.add-client-button {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  color: #FBFBFB;
  font-size: 12px;
  border: none;
  padding: 8px 25px;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 400;
}

.add-client-button:hover {
  background-color: #2e4a2e;
}

.cancel-button {
  background-color: transparent;
  color: #40513B;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  border: none;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 400;
}

/* .cancel-button:hover {
  background-color: #f2f2f2;
} */
/* AddClient Modal End */


/* Create Task Page start */
.create-task-container {
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.create-task-container h2 {
  color: #2d3748;
  margin-bottom: 20px;
  font-family: 'Roboto Slab';
}

.task-information, .upload-documents {
  margin-bottom: 20px;
}
.task-fields{
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* justify-content: center; */
  align-items: center;
  gap: 15px 40px;
  flex-wrap: wrap;

}

/* Manage Client Screen tabs Start */
/* Tab Container */
.tab-container {
  display: flex;
  flex-wrap: wrap; /* Wrap tabs in smaller screens */
  justify-content: flex-start;
  margin-bottom: 5px; /* Reduced gap between tabs and content */
  /* background-color: #ffffff; */
}

/* Individual Tab */
.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 2px solid #9DC08B;
  background-color: #EDF1D6;
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  border-bottom: none;
  text-align: center;
  /* flex: 1; Equal width tabs */
  /* max-width: 200px; Limit max width */
  color: #060606;
  font-size: 12px;
  font-family: 'roboto';
  font-weight: 400;
  letter-spacing: 0.6px;
}

/* Active Tab */
.active-tab {
  background-color: #ffffff;
  border: 2px solid #9DC08B;
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  border-bottom: none;
  color: #060606;
  font-size: 12px;
  font-family: 'roboto';
  font-weight: 400;
  letter-spacing: 0.6px;
}

/* Content Card */
.content-container {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-left: 2px solid #9DC08B; /* Left border with the specified green color */
  border-radius: 0 8px 8px 8px;
  margin-top: -5px; /* Overlaps the border to remove the gap between the tabs and content */
  border-top: none;
  margin-bottom: 30px;
}

.header-container-manage-client {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping for smaller screens */
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.header-left {
  max-width: 60%;
}

.company-name {
  font-size: 48px;
  color: #1F384C;
  margin: 0;
  font-weight: 500;
  font-family: 'Roboto Slab';
}

.company-details {
  font-size: 14px;
  color: #060606;
  margin: 5px 0 0;
  /* font-family: 'Montserrat'; */
  font-weight: 500;
}

.header-right {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
  margin-bottom: 35px;
}

.action-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #40513B;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.action-item img {
  width: 16px;
  height: 16px;
}

.contact-client {
  width: 100%;
  text-align: right;
  margin-top: -15px;
  font-size: 14px;
  color: #060606;
  /* font-family: 'Montserrat', sans-serif; */
  font-weight: 500;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .header-container-manage-client {
    flex-direction: column; /* Stacks content vertically */
    text-align: center;
    padding-bottom: 10px;
  }

  .header-left {
    max-width: 100%;
  }

  .company-name {
    font-size: 36px; /* Reduce font size for smaller screens */
  }

  .header-right {
    justify-content: center; /* Center action buttons */
    gap: 15px; /* Reduce gap between buttons */
    margin-top: 15px;
  }

  .action-item {
    font-size: 10px; /* Reduce font size for action items */
  }

  .contact-client {
    margin-top: 10px;
    text-align: center; /* Center contact details */
  }
}

/* Extra Small Screens (Mobile Devices) */
@media (max-width: 480px) {
  .company-name {
    font-size: 28px; /* Further reduce font size */
  }

  .action-item {
    font-size: 9px; /* Smaller font for buttons */
    gap: 5px; /* Reduce gap between icon and text */
  }

  .action-item img {
    width: 14px;
    height: 14px; /* Reduce icon size */
  }

  .contact-client {
    font-size: 12px; /* Smaller font size for contact details */
    margin-top: 5px;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .tab {
      flex: none; /* Remove equal width on smaller screens */
      /* width: 100%;  */
  }

  /* .tab-container {
      flex-direction: column; 
  } */
}

/* manage Client Screen tabs End */

/* Individual Filter Field */
.filter-field {
  position: relative;
  display: inline-block;
  flex-direction: column;
  gap: 5px;
  /* width: 100%; */
}

.filter-field label {
  font-size: 12px;
  color: #343c44;
  font-weight: 400;
  font-family: 'Roboto';
}

/* Input Styling */
.filter-input {
  padding: 8px 12px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  font-size: 14px;
  color: #343c44;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 40px 10px 10px;
  font-size: 14px;
}

.filter-input:focus {
  outline: none;
  border-color: #40513b;
}

/* Date Range Container */
.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-input {
  position: relative;
}

.date-input + img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
}

/* Hide the default calendar icon but keep functionality */
.filter-input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0; /* Makes the default icon invisible */
  position: absolute;
  right: 0;
  width: 100%; /* Ensures click area covers input */
  height: 100%;
  cursor: pointer;
}

.filter-input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 40px; /* Space for the custom icon */
  position: relative;
}

/* Personal Information styling */
.basic-info-container-border{
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-left: 2px solid #9DC08B;
  border-radius: 0 8px 8px 8px;
  margin-top: -5px;
  border-top: none;
  border-bottom: none;
  border-right: none;
  margin-bottom: 20px;
}
.basic-info-container {
  background-color: #ffffff;
}
.basic-info-header {
  font-size: 18px;
  color: #609966;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: 'Roboto Slab';
}
.basic-info-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid black;
}
.basic-info-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.basic-info-item {
  flex: "1 1 calc(25% - 20px)";
  min-width: 200px;
}
.basic-info-label {
  font-weight: 400;
  color: #767676;
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: 'Roboto';
}
.basic-info-value {
  font-size: 14px;
  color: #060606;
  font-weight: 400;
  font-family: 'Roboto';
}
.basic-info-outer-card {
  border-bottom: 2px solid black;
  padding: 10px;
  border-left: 1px solid #EFF0F4;
  border-right: 1px solid #EFF0F4;
  border-top:  1px solid #EFF0F4;
}

/* Error Messasge styling */

.errorBox {
  color: red;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 12px;
}

.verifyButton{width: 100%;
  max-width: 360px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: #40513b;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 30px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  color: #fbfbfb;
  line-height: 28px;
  border: none;
}

@media (max-width: 768px) {
  .card-invoice-summary {
    width: 90%
  }
}

.teamdetails-title {
  color: #1F384C;
  font-weight: bold;
  font-size: 48px;
  font-family: 'Roboto Slab';
}

.teamdetails-outer-card {
  border-radius: 8px;
 
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-bottom: none;
  border-right: none;
  border-top: none;
  border-left: 2px solid #9DC08B;
}



.tasksScreen-filters-container-overlay {
  position: absolute;
  width: 99%;
  background: #ffffff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding: 10px;
  background-color: #ffffff;
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between image and label */
}

.custom-checkbox-image {
  width: 16px; /* Adjust size */
  height: 16px;
  cursor: pointer;
  transition: transform 0.2s ease; /* Smooth click effect */
}

.custom-checkbox-label {
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.custom-checkbox-container:active .custom-checkbox-image {
  transform: scale(0.95); /* Shrink effect on click */
}

.list-success-msg{
  color: green;
  text-align: center;
  font-size: 12px;
  padding: 10px;
}

.input-field2 {
  height: 40px;
    border: none;
    border-radius: 6px;
    padding: 0 20px;
    background-color: #fff;
    box-sizing: border-box;
    outline: none;
}

.input-field2::placeholder {
  color: #999;
}
input::placeholder {
  color: gray;
}
.div-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  flex-wrap: wrap;
  gap: 20px;
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scrolling-text {
  display: inline-block;
  animation: scrollText 20s linear infinite; /* Adjust timing as needed */
}

.sidebar.overlay {
  display: block;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .sidebar.overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    z-index: 1000;
  }
}

.sidebar.overlay {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  z-index: 1000;
}

@media (max-width: 768px) {

  /* Mobile-specific styles */
  .app-contant {
    width: 100%;
    background-color: var(--background-color);
  }

  .sidebar {
    display: none;
    /* Hide sidebar by default */
  }

  .sidebar.overlay {
    display: block;
    /* Display sidebar as an overlay */
    position: fixed;
    width: 250px;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateX(0);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Slide in for overlay */
  }

  .sidebar-menu button {
    padding: 15px;
    font-size: 16px;
    justify-content: flex-start;
    /* Align menu items to the left */
    width: 100%;
  }

  .profile-image {
    width: 40px;
    height: 40px;
  }

  .user-name-sidebar {
    font-size: 14px;
  }

  .header {
    border-top-left-radius: 0px;
  }

  .body1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  /* Tablet-specific styles */
  .app-contant {
    width: calc(100%);
    /* Adjust for collapsed sidebar */
    background-color: var(--background-color);
  }

  .sidebar {
    width: 50px;
    /* Collapsed sidebar for tablets */
  }

  .sidebar-menu button {
    padding: 12px;
    font-size: 14px;
    justify-content: center;
    /* Center-align menu items */
  }

  .sidebar-menu-li span {
    display: none;
    /* Hide menu item text */
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }

  .user-name-sidebar {
    font-size: 12px;
  }

  .body1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {

  /* Small desktop-specific styles */
  .app-contant {
    width: calc(100%);
    /* Adjust for 15% sidebar */
    background-color: var(--background-color);
  }

  .sidebar {
    width: 15%;
    /* Smaller sidebar for smaller desktops */
  }

  .sidebar-menu button {
    padding: 10px;
    font-size: 14px;
    justify-content: flex-start;
    /* Align menu items to the left */
  }

  .sidebar-menu-li span {
    display: inline-block;
    /* Show menu item text */
  }

  .body1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1440px) {

  /* Large desktop-specific styles */
  .app-contant {
    width: calc(100%);
    /* Adjust for full sidebar */
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    width: 18%;
    /* Full sidebar width for large screens */
  }

  .sidebar-menu button {
    padding: 12px;
    font-size: 16px;
    justify-content: flex-start;
    /* Align menu items to the left */
  }

  .body1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Search Bar Container */
.search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Search Bar Styles */
.search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  width: 250px;
  max-width: 100%;
  height: 15px;
  position: relative;
}

.search-input {
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 12px;
  color: #a0a0a0;
  width: 100%;
}

.search-bar input {
  width: 178px;
  font-size: 10px;
}

@media (max-width: 768px) {
  .search-bar {
    max-width: 185px;
  }

  .search-input {
    font-size: 8px;
  }
}

/* Title and Button Container */
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.header-greeting {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  flex: 1;
}

@media (max-width: 768px) {
  .header-greeting {
    font-size: 18px;
  }
}


@media (max-width: 768px) {
  .add-client-button-header {
    margin-right: 0px;
  }

  .tab {
    padding: 10px 10px;
  }

  .content-container {
    padding: 10px;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
}


@media (max-width: 430px) {
  .header-controls {
    justify-content: space-between;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.logoContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.logo-list-error-msg {
  color: red;
  text-align: start;
  font-size: 12px;
  padding: 15px;
  margin-top: 5px;
}

.upload-container {
  border: 1px solid #888;
  border-radius: 50px;
  padding: 7px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;
  width: 200px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .tasksScreen-pagination {
    gap: 3px;
    /* Reduce spacing between buttons */
    flex-wrap: wrap;
  }
  .upload-container {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .logo-list-error-msg {
    padding: 0px;
  }
  .logoContainer {
    gap: 5px;
  }
}


